(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    /**
     * Kendo UI v2024.1.319 (http://www.telerik.com/kendo-ui)
     * Copyright 2024 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
     *
     * Kendo UI commercial licenses may be obtained at
     * http://www.telerik.com/purchase/license-agreement/kendo-ui-complete
     * If you do not own a commercial license, this file shall be governed by the trial license terms.
     */
    (function( window, undefined$1 ) {
        kendo.cultures["nl-NL"] = {
            name: "nl-NL",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ".",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n%","n%"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "Euro",
                    abbr: "EUR",
                    pattern: ["$ -n","$ n"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "€"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["zondag","maandag","dinsdag","woensdag","donderdag","vrijdag","zaterdag"],
                        namesAbbr: ["zo","ma","di","wo","do","vr","za"],
                        namesShort: ["zo","ma","di","wo","do","vr","za"]
                    },
                    months: {
                        names: ["januari","februari","maart","april","mei","juni","juli","augustus","september","oktober","november","december"],
                        namesAbbr: ["jan","feb","mrt","apr","mei","jun","jul","aug","sep","okt","nov","dec"]
                    },
                    AM: [""],
                    PM: [""],
                    patterns: {
                        d: "d-M-yyyy",
                        D: "dddd d MMMM yyyy",
                        F: "dddd d MMMM yyyy HH:mm:ss",
                        g: "d-M-yyyy HH:mm",
                        G: "d-M-yyyy HH:mm:ss",
                        m: "d MMMM",
                        M: "d MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "HH:mm",
                        T: "HH:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": "-",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

    /**
     * Kendo UI v2024.1.319 (http://www.telerik.com/kendo-ui)
     * Copyright 2024 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
     *
     * Kendo UI commercial licenses may be obtained at
     * http://www.telerik.com/purchase/license-agreement/kendo-ui-complete
     * If you do not own a commercial license, this file shall be governed by the trial license terms.
     */
    (function( window, undefined$1 ) {
        kendo.cultures["en-NL"] = {
            name: "en-NL",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ".",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n%","n%"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "Euro",
                    abbr: "EUR",
                    pattern: ["$ -n","$ n"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "€"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
                        namesAbbr: ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
                        namesShort: ["Su","Mo","Tu","We","Th","Fr","Sa"]
                    },
                    months: {
                        names: ["January","February","March","April","May","June","July","August","September","October","November","December"],
                        namesAbbr: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
                    },
                    AM: ["am","am","AM"],
                    PM: ["pm","pm","PM"],
                    patterns: {
                        d: "dd/MM/yyyy",
                        D: "dddd, d MMMM yyyy",
                        F: "dddd, d MMMM yyyy HH:mm:ss",
                        g: "dd/MM/yyyy HH:mm",
                        G: "dd/MM/yyyy HH:mm:ss",
                        m: "d MMMM",
                        M: "d MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "HH:mm",
                        T: "HH:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": "/",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

    /**
     * Kendo UI v2024.1.319 (http://www.telerik.com/kendo-ui)
     * Copyright 2024 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
     *
     * Kendo UI commercial licenses may be obtained at
     * http://www.telerik.com/purchase/license-agreement/kendo-ui-complete
     * If you do not own a commercial license, this file shall be governed by the trial license terms.
     */
    (function( window, undefined$1 ) {
        kendo.cultures["en-GB"] = {
            name: "en-GB",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ",",
                ".": ".",
                groupSize: [3],
                percent: {
                    pattern: ["-n%","n%"],
                    decimals: 2,
                    ",": ",",
                    ".": ".",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "British Pound",
                    abbr: "GBP",
                    pattern: ["-$n","$n"],
                    decimals: 2,
                    ",": ",",
                    ".": ".",
                    groupSize: [3],
                    symbol: "£"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
                        namesAbbr: ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
                        namesShort: ["Su","Mo","Tu","We","Th","Fr","Sa"]
                    },
                    months: {
                        names: ["January","February","March","April","May","June","July","August","September","October","November","December"],
                        namesAbbr: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
                    },
                    AM: ["AM","am","AM"],
                    PM: ["PM","pm","PM"],
                    patterns: {
                        d: "dd/MM/yyyy",
                        D: "dd MMMM yyyy",
                        F: "dd MMMM yyyy HH:mm:ss",
                        g: "dd/MM/yyyy HH:mm",
                        G: "dd/MM/yyyy HH:mm:ss",
                        m: "d MMMM",
                        M: "d MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "HH:mm",
                        T: "HH:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": "/",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

    /**
     * Kendo UI v2024.1.319 (http://www.telerik.com/kendo-ui)
     * Copyright 2024 Progress Software Corporation and/or one of its subsidiaries or affiliates. All rights reserved.
     *
     * Kendo UI commercial licenses may be obtained at
     * http://www.telerik.com/purchase/license-agreement/kendo-ui-complete
     * If you do not own a commercial license, this file shall be governed by the trial license terms.
     */
    (function( window, undefined$1 ) {
        kendo.cultures["en-DE"] = {
            name: "en-DE",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ".",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n %","n %"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "Euro",
                    abbr: "EUR",
                    pattern: ["-n $","n $"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "€"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"],
                        namesAbbr: ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"],
                        namesShort: ["Su","Mo","Tu","We","Th","Fr","Sa"]
                    },
                    months: {
                        names: ["January","February","March","April","May","June","July","August","September","October","November","December"],
                        namesAbbr: ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
                    },
                    AM: ["am","am","AM"],
                    PM: ["pm","pm","PM"],
                    patterns: {
                        d: "dd/MM/yyyy",
                        D: "dddd, d MMMM yyyy",
                        F: "dddd, d MMMM yyyy HH:mm:ss",
                        g: "dd/MM/yyyy HH:mm",
                        G: "dd/MM/yyyy HH:mm:ss",
                        m: "d MMMM",
                        M: "d MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "HH:mm",
                        T: "HH:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": "/",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

    "bundle all";
    //export default kendo;

}));
